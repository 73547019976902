
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class apply_history extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  results_name: '',
  user_name: '',
  status: 0

}

rule_t_search_data = {

}

t_detail_dialog = {
  open: false,
  title: '详情'
}

t_detail_data = {
  user_name: '',
  reason: ''

}

status_22 = new Map<any, string>([[0, '申请中'], [1, '同意'], [2, '拒绝']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    results_name: this.t_search_data.results_name,
    user_name: this.t_search_data.user_name,
    status: this.t_search_data.status

  }
  Api.http_apply_historyt0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_detail_init(row) {
  Api.http_apply_historytget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_detail_data.user_name = res.data.user_name;
      this.t_detail_data.reason = res.data.reason;
      this.t_detail_dialog.open = true;
    }
  })
}
t_detail_cancle() {
  this.t_detail_dialog.open = false;
}
t_detail_ok() {
  this.t_detail_dialog.open = false;
}
t_init() {
  this.t_get_all_data(0, 10);
}
created() {
  this.t_init();
}
}
